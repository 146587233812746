<template>
  <div class="w-100 p-1">

    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">資料分類</label>
      <b-form-input id="input-small" size="sm" placeholder="Enter your name"
        v-model="pointTemp.eleType"></b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">座標</label>
      <b-form-input class="flex-fill" id="input-small" size="sm" placeholder="請輸入座標"
        v-model="pointTemp.coordinate"></b-form-input>
      <b-button variant="outline-secondary" size="sm" style="min-width: 50px;" @click="takePoint()">
        載入</b-button>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">景點名稱</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入景點名稱" v-model="pointTemp.name">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">國家</label>
      <b-form-input id="input-small" size="sm" placeholder="Enter your name"
        v-model="pointTemp.Country"></b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">城市</label>
      <b-form-input id="input-small" size="sm" placeholder="Enter your name"
        v-model="pointTemp.city"></b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">郵遞區號</label>
      <b-form-input id="input-small" size="sm" placeholder="Enter your name"
        v-model="pointTemp.zipCode"></b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">地址</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入地址" v-model="pointTemp.address">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">關鍵字</label>
      <b-form-textarea id="textarea-state" v-model="pointTemp.keyword" placeholder="請輸入關鍵字"
        rows="3" max-rows="3"></b-form-textarea>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">連絡電話</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入連絡電話" v-model="pointTemp.phone">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">傳真號碼</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入傳真號碼" v-model="pointTemp.fax">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">官方網站</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入官方網站" v-model="pointTemp.websiteUrl">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">詳細介紹</label>
      <b-form-textarea id="textarea-state" v-model="pointTemp.descriptionDetail"
        placeholder="請輸入詳細介紹" rows="3" max-rows="3"></b-form-textarea>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">簡介</label>
      <b-form-textarea id="textarea-state" v-model="pointTemp.description" placeholder="請輸入簡介"
        rows="3" max-rows="3"></b-form-textarea>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">Google街景</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入Google街景" v-model="pointTemp.iframe">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center mb-1">
      <label style="min-width: 100px" for="input-small">Google地圖</label>
      <b-form-input id="input-small" size="sm" placeholder="請輸入Google地圖" v-model="pointTemp.mapUrl">
      </b-form-input>
    </div>
    <div class="d-flex align-items-center">
      <b-button variant="primary" size="sm" style="min-width: 50px;" @click="takePoint()">新增
      </b-button>
    </div>
  </div>
</template>

<script>
  import ctrl from "./js/AddPoint.js";
  export default ctrl;

</script>
