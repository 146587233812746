import store from "@/store";

export default {
  name: "AddPoint",
  data() {
    return {
      pointTemp: {
        eleType: "",
        coordinate: "",
        name: "",
        Country: "",
        city: "",
        zipCode: "",
        address: "",
        keyword: "",
        phone: "",
        fax: "",
        websiteUrl: "",
        description: "",
        descriptionDetail: "",
        mapUrl: "",
        iframe: "",
      }
    }
  },

  methods: {
    takePoint() {
      const point = this.point;
      this.pointTemp.coordinate = point.join(",");
    }
  },

  computed: {
    point: {
      get() {
        return store.state.map.mapData.mapcenter;
      }
    }
  },

  mounted() {
    store.dispatch("Public/getType", {
      table: "public_class",
      page: "1",
      pagenumber: "all",
      search: {
        sametext: {
          func: "eleType",
          isonline: "1"
        }
      },
      orderBy: {
        name: "orders",
        order: "asc"
      },
      defaultfield: ["uid", "name", "values"]
    });

    store.dispatch("Public/getCountry", {
      table: "Country",
      page: "1",
      pagenumber: "all",
      search: {
        sametext: {
          online: "1",
        }
      },
      orderBy: {
        name: "orders",
        order: "asc"
      },
      defaultfield: ["uid", "name", "smallEng"]
    });
  },
}
